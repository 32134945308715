<template>
  <div>
    <img 
      v-show='!hideTopLogo' 
      :src='logoUrlTop'
      :class='logoCssClasses'
      :style='logoStyle'>
      <slot></slot>
    <input v-show='!hideEmailField'
      v-model='email'
      class='mt-16 border-b-2 w-full p-2'
      type='text'
      :placeholder='emailFieldPlaceholderText'
      @keypress.enter='enterOnEmail'>
    <input 
     v-show='!hidePasswordField'
      v-model='password'
      class='border-b-2 mt-12 w-full p-2'
      :type='passwordFieldInputType'
      :placeholder='passwordFieldPlaceholder'>
    <div v-for='(term, index) in terms'
      :key='`term-${index}`'
      class='text-xs border rounded-md mt-8'>
      <div class='bg-gray-100 p-4' v-html='term.text'></div>
      <label class='border-t p-4 flex flex-row justify-start items-center gap-x-0.5 text-normal'>
        <input type='checkbox' :name='`checkbox-${index}`' v-model='term.agreed'>
        <span class='px-1'>{{ term.confirmationText }}</span>
      </label>
    </div>
    <ul v-if='!hideFieldEntryConfirmation'
      class='mt-8 list-disc list-inside text-red-600 text-xs text-left'>
      <li v-if='emptyEmailField'>{{ emailFieldEntryConfirmation }}</li>
      <li v-if='emptyPasswordField'>{{ passwordFieldEntryConfirmation }}</li>
      <li v-if='notAgreedTerms'>개인정보의 제공 및 위탁에 동의해 주시기 바랍니다.</li>
    </ul>
    <button
      class='mt-6'
      :class='buttonCssClasses'
      :style='buttonStyle'
      :disabled='emptyEmailField || emptyPasswordField || notAgreedTerms'
      @click='enterConference'>
      {{ loginButtonString }}
    </button>
    <router-link v-if='includeSignupLink'
      :to='{ name: "Signup" }'
      class='text-sm mt-4 flex flex-row justify-center text-gray-600'>
      Signup
    </router-link>
    <slot name='bottom-content'></slot>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { mapFields }              from 'vuex-map-fields'
import SignupTermsHelpers         from '@/utils/signup-terms-helpers.js'

export default {
  name: 'LoginForm',
  props: [
    'singleSession',

    'logoUrl',
    'hideTopLogo',
    'logoCssClasses',
    'logoCustomCss',
    'logoHeight',

    'emailPlaceholder',
    'emailFieldDefaultValue',
    'hideEmailField',

    'hidePasswordField',
    'passwordFieldDefaultValue',
    'customPasswordFieldInputType',
    'passwordPlaceholder',
    'passwordFieldName',

    'hideFieldEntryConfirmation',
    'customEmailFieldEntryConfirmation',
    'customPasswordFieldEntryConfirmation',

    'loginButtonText',
    'buttonCssClassOverride',
    'buttonStyleOverride',
    'includeSignupLink',
  ],
  data () {
    return {
      email: '',
      password: ''
    }
  },
  watch: {
    passwordFieldDefaultValue: {
      immediate: true,
      handler (val) {
        this.password = val
      }
    },
    emailFieldDefaultValue: {
      immediate: true,
      handler(val) {
        this.email = val
      }
    },
    'showingEventId': {
      handler: function (newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          this.terms = SignupTermsHelpers.signupTerms(newVal)
        }
      },
      immediate: true 
    },
  },
  computed: {
    ...mapGetters('events', [
      'eventConfigLogoImageUrl',
      'eventMainThemeColor',
      'showingEventId',
      'showingEventLoginPageConfigurations',
    ]),
    ...mapFields('users', [
      'terms'
    ]),
    buttonStyle () {
      return (this.buttonStyleOverride) ? this.buttonStyleOverride : `background-color: ${this.eventMainThemeColor};`
    },
    buttonOverrideCss () {
      return (this.buttonCssClassOverride) ? this.buttonCssClassOverride : 'w-full p-4 rounded-md text-white'
    },
    buttonCssClasses () {
      return `${this.emptyFieldLoginButtonClass} ${this.buttonOverrideCss}`
    },
    emptyFieldLoginButtonClass () {
      return (this.needToInputLoginField || this.notAgreedTerms) ? 'opacity-25 cursor-not-allowed' : ''
    },
    emptyEmailField () {
      return !this.email
    },
    emptyPasswordField () {
      return !this.password
    },
    loginButtonString () {
      return (this.loginButtonText) ? this.loginButtonText : 'Login'
    },
    logoUrlTop () {
      return (this.logoUrl) ? this.logoUrl : this.eventConfigLogoImageUrl
    },
    logoHeightStyle () {
      return (this.logoHeight) ? `height: ${this.logoHeight};` : 'height: 3rem;'
    },
    logoCustomStyle () {
      return (this.logoCustomCss) ? `${this.logoCustomCss}` : ''
    },
    logoStyle () {
      return `${this.logoHeightStyle} ${this.logoCustomStyle}`
    },
    needToInputLoginField () {
      return this.emptyEmailField || this.emptyPasswordField
    },
    emailFieldPlaceholderText () {
      return (this.emailPlaceholder) ? this.emailPlaceholder : 'email'
    },
    passwordFieldNameString () {
      return (this.passwordFieldName) ? this.passwordFieldName : 'password'
    },
    passwordFieldPlaceholder () {
      if (this.passwordPlaceholder) {
        return this.passwordPlaceholder
      }
      return this.passwordFieldNameString
    },
    emailFieldEntryConfirmation () {
      return this.customEmailFieldEntryConfirmation ? this.customEmailFieldEntryConfirmation : `Please enter your ${this.emailFieldPlaceholderText}`
    },
    passwordFieldEntryConfirmation () {
      return this.customPasswordFieldEntryConfirmation ? this.customPasswordFieldEntryConfirmation : `Please enter your ${this.passwordFieldNameString}`
    },
    passwordFieldInputType () {
      return this.customPasswordFieldInputType ? this.customPasswordFieldInputType : 'password'
    },
    singleSessionLoginConfirmMessageText () {
      return (this.showingEventLoginPageConfigurations) ? this.showingEventLoginPageConfigurations.singleSessionLoginConfirmMessageText : 'You are already signed in on another device or browser. Logging in will sign you out of those devices. Do you want to proceed?'
    },
    singleSessionLoginConfirmButtonText () {
      return (this.showingEventLoginPageConfigurations) ? this.showingEventLoginPageConfigurations.singleSessionLoginConfirmButtonText : 'Yes, Login Here'
    },
    singleSessionLoginCancelButtonText () {
      return (this.showingEventLoginPageConfigurations) ? this.showingEventLoginPageConfigurations.singleSessionLoginCancelButtonText : 'No - keep signed in'
    },
    userParams () {
      return {
        email: this.email,
        password: this.password,
        singleSession: this.singleSession
      }
    },
    redirectRouteObject () {
      const redirectRoute = this.$route.query.redirect_route ? this.$route.query.redirect_route : 'Home'
      const redirectRouteSponsorId = this.$route.query.sponsor_id ? this.$route.query.sponsor_id : ''

      let routeObject = { name: redirectRoute }

      if (redirectRoute === 'Sponsor' && redirectRouteSponsorId) {
        routeObject['query'] = {sponsor_id: redirectRouteSponsorId }
      }
      return routeObject
    },
    notAgreedTerms () {
      return !this.terms.filter(term => term.required).every(term => term.agreed)
    },
  },
  methods: {
    ...mapActions('users', [
      'login',
      'checkBlacklist',
      'patchTerms',
    ]),
    tryToLogin () {
      this.login(this.userParams).then(() => {
        this.patchTerms()
        this.$router.push(this.redirectRouteObject)
      }).catch(() => {
        this.loginErrorMessage()
      })
    },
    loginErrorMessage () {
      this.$alert('Please confirm your password and login details.', {
        type: 'warning',
        confirmButtonText: 'Close'
      })
    },
    enterOnEmail () {
      if (this.hidePasswordField) {
        this.enterConference()
      } else {
        // do nothing for now... we should move it to the password field later
      }
    },
    enterConference () {
      if (this.singleSession) {
        // singleSession
        this.checkBlacklist(this.userParams).then(resp => {
          if (resp.data.has_token) {
            this.$confirm(this.singleSessionLoginConfirmMessageText, 'Warning', {
              confirmButtonText: this.singleSessionLoginConfirmButtonText,
              cancelButtonText: this.singleSessionLoginCancelButtonText,
              type: 'warning'
            }).then(() => {
              this.tryToLogin()
            }).catch(() => {
              // do nothing. Pressed the cancel button to keep other device/browser logged in
            })
          } else {
            this.tryToLogin()
          }
        }).catch(()=> {
          this.loginErrorMessage()
        })
      } else {
        // normallogin
        this.tryToLogin()
      }
    }
  }
}
</script>
